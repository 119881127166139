import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Button, Grid, Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import GetAppIcon from '@mui/icons-material/GetApp';
import ExportIcon from '@mui/icons-material/Upload';
import ToolsNotifications from '../../tools/ToolsNotifications';
import ToolsDialog from '../../tools/ToolsDialog';
import toolsExportApi from '../../tools/toolsExportApi';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';


export default function Exports({apiRef, typesExport, setSelectionModel, selectionModel, selectedGtins}) {
    const [openLoadingOverlay, setOpenLoadingOverlay] = useState(false);
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const { exportValidationResults, postExportSourceItem } = toolsExportApi();
    const {error, success} = ToolsNotifications();
    const {open: openDialog, close: closeDialog} = ToolsDialog();
    const gpc = useSelector((state) => state.gpc.value);

    const showExportError = (text) => {
        let msg = "Export Error: " + text;
        error(msg);
    }

    const showExportSuccess = (n) => {
        let msg = n + " file(s) exported successfully";
        success(msg);
    }

    const showValidationExportError = (text) => {
        let msg = "Validation Error: " + text;
        error(msg);
    }

    const showValidationExportSuccess = (n) => {
        let msg = n + " file(s) validated successfully";
        success(msg);
    }

    // Generate human friendly message from the validations response
    // TODO: this should probably be in another place, maybe a tools file to manage validations
    const prepareErrors = (errors) => {
        let message = '';
        for(var gtin in errors) {
            // Message supports \n and \t characters
            message += gtin + ':\n\n\t'
            for(var i in errors[gtin]) {
                if(!errors[gtin][i].valid) {
                    message += '(' + errors[gtin][i].attribute + ') ' + errors[gtin][i].error + '\n\t'
                }
            }
        }
        return message
    }
    
    const GenerateId = () => {
        const passwordHintId = uuid();
        return passwordHintId;
    }

    const exportRow = (id) => {
        const passwordHintId = GenerateId();
        const gtin = selectedGtins !== undefined && selectedGtins[id] !== undefined && selectedGtins[id].key !== undefined ? selectedGtins[id].key : id;
        if(selectedDataModel !== undefined) {
            postExportSourceItem(selectedDataModel.type, selectedDataModel.version, id, gpc).then((data) => { 
                    setOpenLoadingOverlay(false);
                    // Creating a Blob for having a csv file format
                    // and passing the data with type
                    const blob = new Blob([data.payload], { type: 'text/xml' });
                
                    // Creating an object for downloading url
                    const url = window.URL.createObjectURL(blob)
                
                    // Creating an anchor(a) tag of HTML
                    const a = document.createElement('a')
                
                    // Passing the blob downloading url
                    a.setAttribute('href', url)
                
                    // Setting the anchor tag attribute for downloading
                    // and passing the download file name
                    a.setAttribute('download', gtin + '-' + passwordHintId + '.xml')
                
                    // Performing a download with click
                    a.click()

                    // Remove the created button
                    //document.removeChild(a)
                    if(data.validation !== undefined) {
                        var errors = prepareErrors(data.validation.detail)
                        showExportError("Validation Error")
                        
                        let dialogTitle = "Validation errors";
                        let dialogContent = errors;
                        let dialogActions = [<Button onClick={closeDialog}>Ok</Button>];
                        openDialog(dialogTitle, dialogContent, dialogActions)
                    } else {
                        showExportSuccess();
                    }
                    
                
            }).catch(function(error) {                        // catch
                console.log(error)
                showExportError("Server failed")
            });
        }
    }

    const batchExport = () => {
        if(selectionModel.length > 0) {
            setOpenLoadingOverlay(true);
            selectionModel.map((id) => {
                exportRow(id);
            })
            setSelectionModel([]);
        } else {
            showExportError("Select at least 1 row to export")
        }
    }
    
    const validateRows = () => {
        if(selectionModel.length > 0) {
            setOpenLoadingOverlay(true);
            exportValidationResults(selectedDataModel.type, selectedDataModel.version, gpc, selectionModel).then((data) => { 
                setOpenLoadingOverlay(false);
                // Creating a Blob for having a csv file format
                // and passing the data with type
                const blob = new Blob([data.payload], { type: 'text/xml' });
            
                // Creating an object for downloading url
                const url = window.URL.createObjectURL(blob)
            
                // Creating an anchor(a) tag of HTML
                const a = document.createElement('a')
            
                // Passing the blob downloading url
                a.setAttribute('href', url)
            
                // Setting the anchor tag attribute for downloading
                // and passing the download file name
                a.setAttribute('download', 'validationResultsDownload.xml')
            
                // Performing a download with click
                a.click()

                // Remove the created button
                //document.removeChild(a)
                if(data.validation !== undefined && data.validation.valid === 0) {
                    var errors = prepareErrors(data.validation.detail)
                    showValidationExportError("Check error detail")
                    
                    let dialogTitle = "Validation errors";
                    let dialogContent = errors;
                    let dialogActions = [<Button onClick={closeDialog}>Ok</Button>];
                    openDialog(dialogTitle, dialogContent, dialogActions)
                } else {
                    showValidationExportSuccess(selectionModel.length);
                }
            }).catch(function(error) {                        // catch
                console.log(error)
                setOpenLoadingOverlay(false);
                showValidationExportError("Server failed")
            });
            setSelectionModel([]);
        } else {
            showValidationExportError("Select at least 1 row to export")
        }
    }
    
    const csvOptions = { delimiter: ';' };

    const buttonStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#3284C6",
        fontSize: "12px",
        fontWeight: "400",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        },
        padding: '5px 30px 5px 16px',
        marginBottom: '20px'
    }
  
    return  typesExport !== undefined  ?
                <Grid container columnSpacing={1}>
                    {Object.keys(typesExport).includes('csv') ?
                        <Grid item>
                            <Button
                                sx={buttonStyle} 
                                onClick={() => apiRef.current.exportDataAsCsv()}
                                startIcon={<GetAppIcon />}
                                disabled={typesExport['csv'].disabled}
                            >
                                Export as CSV
                            </Button>
                        </Grid>
                    :
                        ''
                    }
                    {Object.keys(typesExport).includes('xml') ?
                        <Grid item >
                            <Button 
                                sx={buttonStyle} 
                                onClick={batchExport}
                                startIcon={<ExportIcon />}
                                disabled={typesExport['xml'].disabled}
                            >
                                Export as XML
                            </Button>
                        </Grid>
                    :
                        ''
                    }
                    {Object.keys(typesExport).includes('validationXml') ?
                        <Grid item >
                            <Button 
                                sx={buttonStyle} 
                                onClick={validateRows}
                                startIcon={<VerifiedUserIcon />}
                                disabled={typesExport['validationXml'].disabled}
                            >
                                Validate
                            </Button>
                        </Grid>
                    :
                        ''
                    }
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 99999 }}
                        open={openLoadingOverlay}
                    >
                        <CircularProgress />
                    </Backdrop>
                </Grid>
            :
                ''
}